.inputNumber {
  font-size: 13px;
  width: calc(100% - 15px);
  transition: .4s;
  margin: 3px 7px;
  padding: 3px 0px;
  border-radius: 13px;
  border: 2px solid #18365e;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputNumber button{
  background-color: #18365e;
  color: #fff;
  padding: 3px 20px;
  border: none;
  font-size: 14px;
  transition: .4s;
  margin: 0px 5px;
  transition: .4s;
}
.inputNumber input {
  padding: unset!important;
  font-size: unset!important;

  margin: unset!important;
  padding: unset!important;
  border-radius: unset!important;

  width: calc(100% - 15px);
  transition: .4s;
  /* border: none!important; */
  border: 2px solid #18365e;
  text-align: center;
}

.inputNumber input:focus {
  border: none!important;
}

.inputNumber .displayNumber {
  width: 100%;
}

.inputNumber button:hover{
  background-color: #4CAF50;
}

.inputNumber button:disabled{
  background-color: #18365e73;
}

.inputNumber button:first-child {
  border-radius: 10px 0 0 10px;
}

.inputNumber button:last-child {
  border-radius: 0 10px 10px 0;
}

.inputNumber button:disabled:active{
  padding: 3px 25px;
}