.video-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-holder {
    overflow: hidden;
    /* border-radius: 25px; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 653px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: -80px;
    margin-top: -50px;

    border-radius: 1px 10px 100px 1px;

    background-repeat: no-repeat;
    background-size: 100% 250px;
    /* background-attachment: fixed; */
    background-position: center;
    background-color: #fff;
}

.video-container video {
    width: 1295px;
}

.video-container button {
    background: #18365ed1;
    color: #fff;
    border: none;
    padding: 10px 24px;
    border-radius: 22px;
    margin-top: 32px;


    position: absolute;
    visibility: hidden;
    transition: .4s;
}

.video-container button:hover {
    background: #18365e;
}

.video-container button svg {
    margin-top: -5px;
    margin-right: 4px;
}

.video-container:hover button {
    visibility: unset;
}

.video-container .loader {
    position: absolute;
    transition: .4s;
}

.contact-btn {
    margin-left: 17px;
    background: #fff;
    color: #18365e;
    border-radius: 50px;
    padding: 4px 27px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 15px;
    display: inline-block;
    border: 2px solid #18365e;
    font-weight: 600;
    padding: 7px 19px;
}

.contact-btn:hover {
    background: #3e5c83;
    color: #ffffff;
}

.video-frame {
    background-color: black;
    min-height: 200px;
    margin-top:-1px;
    
}

.video-frame iframe {
    width: 100%;
    height: 645px;
}

@media (max-width: 1399px) {
    .video-holder {
        height: 551px;
    }

    .video-container video {
        width: 1116px;
    }
}

@media (max-width: 1199px) {
    .video-holder {
        height: 462px;
    }

    .video-container video {
        width: 936px;
    }
}

@media (max-width: 991px) {
    .video-holder {
        /* min-height: 350px; */
        height: auto;
    }

    .video-holder {
        border-radius: 0px;
        margin-bottom: -25px;
        margin-top: 30px;
        box-shadow: none;
    }

    .video-container video {
        width: 100%;
    }
}