 /*--------------------------------------------------------------
  # university-main Section
  --------------------------------------------------------------*/
  #university-main {
    height: 400px;
    background-size: cover;
    margin-bottom: -123px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c8ebc6ff;
    padding: 0px 20px;
  }

  #university-main svg{
    width: 645px;
  }
  
  #university-main .container {
    position: relative;
  }
  
  #university-main h1 {
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    text-transform: uppercase;
    color: #2c4964;
  }
  
  #university-main h2 {
    color: #2c4964;
    margin: 10px 0 0 0;
    font-size: 24px;
    line-height: 37px;
  }
  
  #university-main .btn-get-started {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    margin-top: 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #18365e;
  }
  
  #university-main .btn-get-started:hover {
    background: #3f7a75;
  }

  .btn-cta {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    margin-top: 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #18365e;
    margin: 3px 5px;
  }
  
  .btn-cta:hover {
    background: #3f7a75;
    color: #fff;
  }


  .university  {
    margin-top: 25px;
    margin-bottom: 60px;
  }
  
  @media (min-width: 1024px) {
    #university-main {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 992px) {
    #university-main {
      margin-bottom: 0;
      height: auto;
      padding-top: 150px;
    }
  
  
    #university-main h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #university-main h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    #university-main {
      flex-direction: column-reverse;
      text-align: center;
      padding-bottom: 34px;
      /* margin-bottom: 30px; */
    }

    #university-main svg{
      width: 80%;
    }

    .university  {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  
  
  /* @media (max-height: 800px) {
    #university-main {
      height: 110vh;
    }
  } */
  
 
  

  /*--------------------------------------------------------------n
  # university
  --------------------------------------------------------------*/
  .university {
    color: #444444;
  }
  
  .university .boxes h3 {
    font-size: 28px;
    font-weight: 700;
    color: #2c4964;
    margin-bottom: 15px;
  }

  .university .boxes p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 37px;
    /* text-align: justify; */
  }

  .university .university-image {
    width: 100%;
  }

  @media (max-width: 992px) {
    .university .boxes h3 {
      text-align: center;
    }

    .university .boxes p {
      text-align: center;
    }
  }
  