 /*--------------------------------------------------------------
  # blog-main Section
  --------------------------------------------------------------*/
 #blog-main {
   height: 400px;
   background-size: cover;
   margin-bottom: -123px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #c8ebc6ff;
   padding: 0px 20px;
 }

 #blog-main svg {
   width: 645px;
 }

 #blog-main .container {
   position: relative;
 }

 #blog-main h1 {
   margin: 0;
   font-size: 40px;
   font-weight: 700;
   line-height: 56px;
   text-transform: uppercase;
   color: #2c4964;
 }

 #blog-main h2 {
   color: #2c4964;
   margin: 10px 0 0 0;
   font-size: 24px;
   line-height: 37px;
 }

 #blog-main .btn-get-started {
   font-family: "Raleway", sans-serif;
   text-transform: uppercase;
   font-weight: 500;
   font-size: 14px;
   letter-spacing: 1px;
   display: inline-block;
   padding: 12px 35px;
   margin-top: 30px;
   border-radius: 50px;
   transition: 0.5s;
   color: #fff;
   background: rgb(24, 54, 94);
 }

 #blog-main .btn-get-started:hover {
   background: #3f7a75;
 }



 @media (min-width: 1024px) {
   #blog-main {
     background-attachment: fixed;
   }
 }

 @media (max-width: 992px) {
   #blog-main {
     margin-bottom: 0;
     height: auto;
     padding-top: 104px;
   }

   #blog-main .container {
    padding-bottom: 33px;
   }

   #blog-main h1 {
     font-size: 28px;
     line-height: 36px;
   }

   #blog-main h2 {
     font-size: 18px;
     line-height: 24px;
     margin-bottom: 30px;
   }

   #blog-main {
     flex-direction: column-reverse;
     text-align: center;
     padding-bottom: 0px;
     margin-bottom: 30px;
   }

   #blog-main svg {
     width: 100%;
   }
 }

 #blog-row .container-fluid {
   padding: 47px 20px;
   transition: .4s;
 }

 #blog-row .blog .blog-bottom {
   background: #18365e;
   padding: 10px;
   margin-top: 10px;
   margin-bottom: -24px;
   margin-left: -24px;
   margin-right: -24px;
   text-align: right;

 }

 #blog-row h5,
 #blog-row p {
   line-height: 30px;
 }

 #blog-row .blog .blog-bottom a {
   color: white;
 }

 #blog-row .blog>.row {
   transition: .4s;
   border-radius: 10px;
 }

 #blog-row .blog>.row .blog-image {
   height: 250px;
   background: #eaebeb;
   background-repeat: no-repeat;
   background-position: center;
 }

 #blog-row .blog>.row:hover .blog-image {
   transition: .4s;
   opacity: .8;
 }


 #blog-row .blog>.row:hover {
   background-color: #c8ebc6ff !important;
 }

 #blog-row .blog>.row:hover .blog-bottom {
   background-color: rgb(30, 102, 26) !important;
 }

 #blog-row {
  transition: .4s;
 }

 /* #blog-row .loading {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #2c4964;
  height: 148px;
  transition: .4s;
 } */

 #post-main .loading  {
  transition: .4s;
  /* background-color: #c8ebc6ff; */
 }
 #post-main .loading {
  display: none;
 }

 #post-main .loading {
    display: unset;
 }

 #post-main .loading svg {
  background: white;
  padding: 17px 12px 13px 13px;
  border-radius: 45px;
  font-size: 69px;
  color: #18365e;
  animation: blinker 1.5s linear infinite;
  width: 1em;
 }


@keyframes blinker {
  50% {
    opacity: 0;
  }
}


 @media (max-width: 992px) {
   #blog-row .container-fluid {
     padding: 37px 10px;
   }
 }

 .blog-clm {
   border-left: 5px solid #18365e;
   padding: 7px;
   transition: .4s;
   border-radius: 3px;
 }

 .blog-clm:hover {
   border-left: 5px solid #2f9e29;
   background-color: #cbcbcc;
 }

 .blog-clm .card-text {
   color: #18365e;
   font-size: 20px;
 }