 /*--------------------------------------------------------------
  # purchase-main Section
  --------------------------------------------------------------*/
  #purchase-main {
    height: 400px;
    background-size: cover;
    margin-bottom: -123px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c8ebc6ff;
    padding: 0px 20px;
  }

  .purchase {
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 60px;
  }

  .purchase .purchase-left-block {
    margin-top: 0px;
    min-height: 600px;
  }

  .purchase .purchase-right-block {
    background: #d4dde3;
    transition: .4s;
  }

  .purchase .purchase-right-block .head-join {
    font-size: 13px;
    text-align: center;
  }


  .purchase .purchase-right-block .sub-head {
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    margin-bottom: 25px;
    
  }

  .purchase .purchase-right-block .sub-head.top-margin {
    margin-top: 14px;
  }

  .purchase .purchase-right-block .inputs {
    margin: 20px 0px;
    text-align: center;
    transition: .4s;
  }

  .purchase .purchase-right-block .inputs.no-top-margin {
    margin: 0px 0px;
  }

  .purchase .purchase-right-block .inputs .input-head {
    font-size: 14px;
    float: left;
    margin: 2px 9px;
    color: #606060;
  }
  
  .purchase .purchase-right-block .inputs input {
    padding: 3px 10px;
    font-size: 13px;
    width: calc(100% - 15px);
    transition: .4s;
    margin: 3px 0;
    padding: 5px 17px;
    border-radius: 13px;
    border: 2px solid #18365e;
  }

  .purchase .purchase-right-block .inputs input + label.error {
    display: none;
  }

  .purchase .purchase-right-block .inputs input:focus {
    padding: 10px 17px;
    border: 2px solid #18365e;
  }

  .purchase .purchase-right-block .inputs input:active {
    padding: 10px 3px;
  }

  .purchase .purchase-right-block .inputs .accordion-item {
    margin: 3px 7px;
    border-radius: 13px;
  }

  .purchase .purchase-right-block .inputs input.error {
    border: 2px solid #ff0000;
    background: #ff000025;
  }

  .purchase .purchase-right-block .inputs input:focus.error {
    border: 2px solid #ff0000;
    background: #ff000025;
  }

  .purchase .purchase-right-block .inputs input.error + label.error {
    display: unset;
    float: left;
    text-align: left;
    font-size: 11px;
    margin-top: -5px;
    padding: 3px 11px;
    color: red;
    width: 100%;
  }

  .purchase .purchase-right-block .inputs .accordion-item .accordion-header button {
    padding: 5px 15px;
    border: 1px;
    font-size: 13px;
    border: 2px solid #18365e;
    border-radius: 0;
    border-radius: 13px;
    font-weight: 500;
  }

  .purchase .purchase-right-block .inputs .accordion-item .accordion-header button:not(.collapsed) {
    background-color: #18365e;
    color: #fff;
    border: unset;
  }

  .purchase .purchase-right-block .inputs .accordion-item .accordion-header button:not(.collapsed)::after {
    color: #fff;
  }

  .purchase .purchase-right-block .selections {
    margin: 20px 0px;
    text-align: center;
  }

  .purchase .purchase-right-block .selections .option {
    border: 2px solid #18365e;
    background-color: #fff;
    color: #000;
    padding: 6px 17px;
    border-radius: 13px;
    font-size: 14px;
    transition: .4s;
    margin: 10px 5px;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
  }

  .purchase .purchase-right-block .selections .option input {
    display: none;
  }

  .purchase .purchase-right-block .selections .option.active {
    background-color: #4CAF50;
    border: 2px solid #18365e;
    color: #fff;
    padding: 10px 17px;
  }

  .purchase .purchase-right-block .selections .option.active input {
    display: unset;
  }


  .purchase .purchase-right-block .selections .option:active {
    padding: 10px 20px;
  }
  .purchase .purchase-right-block .selections .option.active:active {
    padding: 10px 20px;
  }


  .purchase .purchase-right-block .selections .option:hover {
    background-color: #4CAF50;
    border: 2px solid #4CAF50;
    color: #fff;
  }

  .purchase .purchase-right-block .buttons {
    margin: 20px 0px;
    text-align: center;
  }

  .purchase .purchase-right-block .buttons button {
    background-color: #18365e;
    color: #fff;
    padding: 6px 20px;
    border-radius: 15px;
    border: none;
    font-size: 14px;
    transition: .4s;
    font-weight: 410;
  }

  .purchase .purchase-right-block .buttons button:hover {
    background-color: #4CAF50;
  }

  .purchase .purchase-right-block .buttons button:disabled {
    background: #747e8b;
  }

  .purchase .component-head {
    margin: 8px 8px;
    margin-top: 25px;
    font-size: 15px;
    padding: 0px 1px;
    border-bottom: 2px solid #6868744f;
    padding-bottom: 4px;
    font-weight: 600;
    color: #606060;
  }
  

  .purchase .component-text {
    line-height: 26px;
    font-size: 13.9px;
    padding: 10px;
    color: #565b5e;
    letter-spacing: -0.4px;
    font-weight: 500;
  }

  .purchase .component-text ul {
    margin-left: -30px;
    margin-bottom: 0px;
    list-style: none;
  }

  .purchase .component-text ul li::before{
    content: "►";
    display: inline-block;
    padding-right: 7px;
    color: #565b5e;
    font-size: 14px;
  }

  .purchase .component-text .block {
    background: #00000012;
    padding: 3px 13px;
    margin: 7px 0px;
    border-radius: 13px;
    /* display: flex;
    flex-wrap: wrap;
    flex-direction: row; */
  }

  .purchase .component-text .block span {
    float: right;
    font-weight: 600;
  }

  .purchase .component-group {
    padding: 5px;
    background: #00000021;
    border-radius: 13px;
    margin: 10px 0;
  }
    
    

  #purchase-main svg{
    width: 645px;
  }
  
  #purchase-main .container {
    position: relative;
  }
  
  #purchase-main h1 {
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    text-transform: uppercase;
    color: #2c4964;
  }
  
  #purchase-main h2 {
    color: #2c4964;
    margin: 10px 0 0 0;
    font-size: 24px;
    line-height: 37px;
  }
  
  #purchase-main .btn-get-started {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    margin-top: 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: rgb(24, 54, 94);
  }
  
  #purchase-main .btn-get-started:hover {
    background: #3f7a75;
  }
  
  @media (min-width: 1024px) {
    #purchase-main {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 992px) {
    #purchase-main {
      margin-bottom: 0;
      height: auto;
      padding-top: 102px;
    }
  
    #purchase-main .container {
      padding-bottom: 0px;
    }
  
    #purchase-main h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #purchase-main h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    #purchase-main {
      flex-direction: column-reverse;
      text-align: center;
      padding-bottom: 34px;
      /* margin-bottom: 30px; */
    }

    #purchase-main svg{
      width: 100%;
    }
  }
  

  

  /*--------------------------------------------------------------n
  # purchase
  --------------------------------------------------------------*/
  .purchase {
    color: #444444;
  }
  
  .purchase .boxes h3 {
    font-size: 28px;
    font-weight: 700;
    color: #2c4964;
    margin-bottom: 15px;
  }

  .purchase .boxes p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 37px;
    text-align: justify;
  }

  .purchase .image-container {
    display: flex;
    align-items: flex-end
  }

  .purchase .purchase-image {
    width: 100%;
    margin-left: -15px;
    margin-right: -15px;
  }

  @media (max-width: 992px) {
    .purchase {
      margin-bottom: 0px;
    }

    .purchase .boxes h3 {
      text-align: center;
    }

    .purchase .boxes p {
      text-align: center;
    }

    .purchase .purchase-image {
      min-height: unset;
    }

    .purchase .purchase-left-block {
      min-height: 550px;
    }
  }
  

  @media (max-width: 767px) {
  .purchase .purchase-left-block {
    min-height: 450px;
  }
}