 /*--------------------------------------------------------------
  # ocpp-management-software-main Section
  --------------------------------------------------------------*/
  #ocpp-management-software-main {
    height: 400px;
    background-size: cover;
    margin-bottom: -123px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c8ebc6ff;
    padding: 0px 20px;
  }

  #ocpp-management-software-main svg{
    width: 645px;
  }
  
  #ocpp-management-software-main .container {
    position: relative;
  }
  
  #ocpp-management-software-main h1 {
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    /* text-transform: uppercase; */
    color: #2c4964;
  }
  
  #ocpp-management-software-main h2 {
    color: #2c4964;
    margin: 10px 0 0 0;
    font-size: 24px;
    line-height: 37px;
  }
  
  #ocpp-management-software-main .btn-get-started {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    margin-top: 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: rgb(24, 54, 94);
  }
  
  #ocpp-management-software-main .btn-get-started:hover {
    background: #3f7a75;
  }

  #OCPPManagementSoftware .management-softwares{
    line-height: 43px;
    list-style: none;
    margin-left: -37px;
    font-size: 17px;
  }

  #OCPPManagementSoftware .management-softwares li{
    padding: 0 10px;
  }

  /* #OCPPManagementSoftware .management-softwares li:hover{
    transition: .5s;
    cursor: pointer;
    background-color: #d8dde2;
    color: #2c4964;
  } */

  #OCPPManagementSoftware .management-softwares li:before {
    content: "►"; 
    display: inline-block;
    padding-right: 10px;
    color: #2c4964;
  }
  
  @media (min-width: 1024px) {
    #ocpp-management-software-main {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 992px) {
    #ocpp-management-software-main {
      margin-bottom: 0;
      height: auto;
      padding-top: 150px;
    }

  
    #ocpp-management-software-main h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #ocpp-management-software-main h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    #ocpp-management-software-main {
      flex-direction: column-reverse;
      text-align: center;
      padding-bottom: 34px;
    }

    #ocpp-management-software-main svg{
      width: 100%;
    }
  }
  
  
 
  

  /*--------------------------------------------------------------n
  # ocpp-management-software
  --------------------------------------------------------------*/
  .ocpp-management-software {
    color: #444444;
  }
  
  .ocpp-management-software .boxes h3 {
    font-size: 28px;
    font-weight: 700;
    color: #2c4964;
    margin-bottom: 15px;
  }

  .ocpp-management-software .boxes p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 37px;
    text-align: justify;
  }

  .ocpp-management-software .ocpp-management-software-image {
    width: 62%;
  }

  .ocpp-management-software {
    margin-top: 25px;
    margin-bottom: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: 992px) {
    .ocpp-management-software .boxes h3 {
      text-align: center;
    }

    .ocpp-management-software .boxes p {
      text-align: center;
    }

    .ocpp-management-software {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  