/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #18365e;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #298ce5;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

.datepicker-dropdown {
  padding: 20px !important;
}



/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  padding: 9px 0;
  box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
}

#header.header-scrolled {
  top: 0;
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-family: "Poppins", sans-serif;
}

#header .logo .logo-large-device {
  display: block;
}

#header .logo .logo-small-device {
  display: none;
}

#header .logo a {
  color: #2c4964;
}

#header .logo img {
  max-height: 47px;
}

/**
  * partner Button *
  */
.partner-with-us-btn {
  margin-left: 17px;
  background: #fff;
  color: #18365e;
  border-radius: 50px;
  padding: 4px 27px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 15px;
  display: inline-block;
  border: 2px solid #18365e;
  font-weight: 600;
  padding: 7px 19px;
}

.partner-with-us-btn.active {
  background: #4CAF50;
  border: 2px solid #4CAF50;
  color: #ffffff;
}

.partner-with-us-btn:hover {
  background: #18365e13;
  color: #18365e;
}

@media (max-width: 991px) {
  .partner-with-us-btn {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }
}

@media (max-width: 768px) {
  .partner-with-us-btn {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }

  #header .logo img {
    max-height: 32px;
  }

  #header .logo .logo-large-device {
    display: none;
  }

  #header .logo .logo-small-device {
    display: block;
  }

  .navbar button {
    width: calc(100% - 22px);
  }

}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar>ul>li {
  position: relative;
  white-space: nowrap;
  padding: 0;
}

.navbar a,
.navbar button,
.navbar button:focus,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 7px 19px;
  margin: 6px 11px;
  background: #fff;
  color: #18365e;
  border-radius: 50px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 15px;
  border: 2px solid #fff;
}

.navbar a i,
.navbar button i,
.navbar button:focus i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>button {
  color: #fff;
  background-color: #18365e;
  border-color: #18365e;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 20px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul.right-menu {
  left: -152px;
}

.navbar .btn-install-chargers a {
  border: 2px solid #18365e;
}


.navbar .btn-install-chargers a {
  margin-left: 17px;
  background: #fff;
  color: #18365e;
  border-radius: 50px;
  padding: 4px 27px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 15px;
  display: inline-block;
  border: 2px solid #18365e;
  font-weight: 600;
  padding: 7px 19px;
}

.navbar .btn-install-chargers a.active:hover {
  background: #4CAF50;
  border: 2px solid #4CAF50;
  color: #ffffff;
}

.partner-with-us-btn.active a {
  border: 2px solid #18365e;
}

.navbar .btn-install-chargers a.active {
  background: #4CAF50;
  border: 2px solid #4CAF50;
  color: #ffffff;
}

.navbar .btn-install-chargers a:hover {
  background: #18365e13;
  color: #18365e;
}


.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #082744;
  border: none;
  display: flex;
  justify-content: center;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #fff;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.dropdown button svg {
  margin-top: 0px;
  margin-left: 3px;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

#navbar .more-btn {
  border: 2px solid #18365e;
  border-radius: 30px;
  padding: 10px 10px 10px 8px;
}

.show-on-mobile-view {
  display: none;
}


@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

@media (max-width: 1113px) {

  .navbar a,
  .navbar button,
  .navbar button:focus,
  .navbar a:focus {
    margin: 6px;
  }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #2c4964;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: unset;
  }

  .navbar ul {
    display: none;
  }

  .show-on-mobile-view {
    display: unset;
  }

  .hide-on-mobile-view {
    display: none;
  }

  /* for mobile navbar */
  .navbar-mobile {
    position: fixed !important;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(28, 47, 65, 0.9);
    transition: 0.3s;
    z-index: 999;
  }


  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }

  .navbar-mobile>ul>li {
    padding: 0;
  }

  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #2c4964;
    border: none;
  }

  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #ffffff;
  }

  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
  }

  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }

  .navbar-mobile .dropdown.open ul {
    display: block;
  }

  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }

  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }

  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }

  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    border: 1px solid #18365e !important;
  }

  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }

}



.dropdown ul li a {
  border: 1px solid #e6effb !important;
}