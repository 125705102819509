 /*--------------------------------------------------------------
  # partner-with-us-main Section
  --------------------------------------------------------------*/
 #partner-with-us-main {
  /* height: 820px; */
  background: #ffffff;
  margin-top: 83px;
 }

 #partner-with-us-main .banner-image {
  width: 100%;
 }

 #partner-with-us-main .banner-image img{
  width: 100%;
 }

 #partner-with-us-main svg {
   width: 645px;
 }

 #partner-with-us-main  {
   position: relative;
 }

 #partner-with-us-main .content-holder{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  max-height: 100%;
}

 #partner-with-us-main .container{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

 #partner-with-us-main .content{
  width: 633px;
  padding: 80px 22px;
  background: #f9f9f9ad;
  text-align: center;
  margin-left: -12px;
  border-radius: 35px;
  transition: .4s;
}

#partner-with-us-main .content h2 {
  font-size: 18px;
}

 #partner-with-us-main h1 {
   margin: 0;
   font-size: 40px;
   font-weight: 700;
   line-height: 56px;
   text-transform: uppercase;
   color: #2c4964;
 }

 #partner-with-us-main h2 {
   color: #2c4964;
   margin: 10px 0 0 0;
   font-size: 24px;
   line-height: 37px;
 }

 #partner-with-us-main .btn-get-started {
   font-family: "Raleway", sans-serif;
   text-transform: uppercase;
   font-weight: 500;
   font-size: 14px;
   letter-spacing: 1px;
   display: inline-block;
   padding: 12px 35px;
   margin-top: 30px;
   border-radius: 50px;
   transition: 0.5s;
   color: #fff;
   background: rgb(24, 54, 94);
 }

 #partner-with-us-main .btn-get-started:hover {
   background: #3f7a75;
 }

 #partner{
  margin-top: -114px;
  position: relative;
  margin-bottom: 40px;
 }

 #partner .partner-form {
   background-color: #18365e;
 }

 #partner .partner-form .contact-btn {
   margin-left: 17px;
   background: #fff;
   color: #18365e;
   border-radius: 50px;
   padding: 4px 27px;
   white-space: nowrap;
   transition: 0.3s;
   font-size: 15px;
   display: inline-block;
   border: 2px solid #18365e;
   font-weight: 600;
   padding: 7px 19px;
 }

 #partner .partner-form .contact-btn:hover {
   background: #3e5c83;
   color: #ffffff;
 }

 .form-floating>.marin-left-10px {
  margin-left: 10px!important;
 }

 .required-label::after {
  content: " *";
  color: red;
}

 /* @media (min-width: 1024px) {
   #partner-with-us-main {
     background-attachment: fixed;
   }
 } */

 

 @media (max-width: 1709px) {
  #partner{
    margin-top: -50px;
   }
 }

 @media (max-width: 1425px) {

  #partner-with-us-main .content {
    width: 563px;
  }

  #partner{
    margin-top: 40px;
   }
 }

 @media (max-width: 1290px) {

  #partner-with-us-main .content {
    border-radius: 0px;
  }
 }

 

 @media (max-width: 1200px) {

  #partner-with-us-main .content-holder{
    position: unset;
    margin-top: -52px;
  }

  #partner-with-us-main .content {
    width: unset;
    padding: 38px 22px;
    background: #f9f9f9;
    text-align: center;
    margin-left: -12px;
    margin-right: -12px;
    border-radius: 0px;
  }

  #partner{
      margin-top: 40px;
    }
  }


 @media (max-width: 992px) {

  #partner-with-us-main .content-holder{
    margin-top: 0px;
  }

  #partner {
      margin-top: 0px;
      margin-bottom: 0px;
  }


   #partner-with-us-main {
     margin-bottom: 0;
     height: auto;
     /* padding-top: 150px; */
     margin-top: 65px;
   }

   /* #partner-with-us-main .container {
     padding-bottom: 63px;
   } */

   #partner-with-us-main h1 {
     font-size: 28px;
     line-height: 36px;
   }

   #partner-with-us-main h2 {
     font-size: 18px;
     line-height: 30px;
     margin-bottom: 30px;
     text-align: center;
   }

   #partner-with-us-main .content h2 {
      font-size: 16px;
   }

   #partner-with-us-main {
     flex-direction: column-reverse;
     text-align: center;
     /* padding-bottom: 34px; */
   }

   #partner-with-us-main svg {
     width: 100%;
   }

   #partner p {
     padding: 0px 15px;
   }
 }


 /* @media (max-height: 800px) {
   #partner-with-us-main {
     height: 110vh;
   }
 } */




 /*--------------------------------------------------------------n
  # partner
  --------------------------------------------------------------*/
 .partner {
   color: #444444;
 }

 .partner .boxes h3 {
   font-size: 28px;
   font-weight: 700;
   color: #2c4964;
   margin-bottom: 15px;
 }

 .partner .boxes p {
   margin-bottom: 15px;
   font-size: 18px;
   line-height: 37px;
   /* text-align: justify; */
 }

 .partner .partner-image {
   width: 62%;
 }

 @media (max-width: 992px) {
   .partner .boxes h3 {
     text-align: center;
   }

   .partner .boxes h4 {
     text-align: center;
   }

   .partner {
     margin-bottom: 0px;
   }

   .partner .boxes p {
     text-align: center;
   }
 }