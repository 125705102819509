.not-found-main {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    height: 100vh;
}

.not-found-left {
    width: 50%;
    line-height: 31px;
}

.not-found-left .head {
    margin-bottom: 27px;
    font-size: 48px;
}

.not-found-left .button-container {
    height: 47px;
    margin-top: 23px;
}

.not-found-left .button-container .return-to-home {
    background: #18365E;
    padding: 10px 22px;
    border-radius: 18px;
    color: white;
    text-decoration: none;
    transition: .4s;
}

.not-found-left .button-container .return-to-home:hover {
    background: #50B965;
}


.not-found-right {
    width: 50%;
    text-align: center;
}

.not-found-right svg {
    width: 80%;
}

@media (max-width: 992px) {
    .not-found-main {
        margin-top: 57px;
        flex-direction: column;
        height: 100%;
    }

    .not-found-left .head {
        margin-bottom: 27px;
        font-size: 31px;
        margin-top: 40px;
        text-align: center;
    }

    .not-found-left .button-container {
        text-align: center;
    }

    .not-found-left , .not-found-right{
        width: 100%;
    }

    .not-found-right {
        height: 100%;
    }

    .not-found-left .text-center {
        text-align: center;
    }
    
    
}
  