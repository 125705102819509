 /*--------------------------------------------------------------
  # faq-main Section
  --------------------------------------------------------------*/
 #faq-main {
   height: 400px;
   background-size: cover;
   margin-bottom: -123px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #c8ebc6ff;
   padding: 0px 20px;
 }

 #faq-main svg {
   width: 645px;
 }

 #faq-main .container {
   position: relative;
 }

 #faq-main h1 {
   margin: 0;
   font-size: 40px;
   font-weight: 700;
   line-height: 56px;
   text-transform: uppercase;
   color: #2c4964;
 }

 #faq-main h2 {
   color: #2c4964;
   margin: 10px 0 0 0;
   font-size: 24px;
   line-height: 37px;
 }



 @media (min-width: 1024px) {
   #faq-main {
     background-attachment: fixed;
   }
 }

 @media (max-width: 992px) {
   #faq-main {
     margin-bottom: 0;
     height: auto;
     padding-top: 104px;
   }

   #faq-main .container {
     padding-bottom: 33px;
   }

   #faq-main h1 {
     font-size: 28px;
     line-height: 36px;
   }

   #faq-main h2 {
     font-size: 18px;
     line-height: 24px;
     margin-bottom: 30px;
   }

   #faq-main {
     flex-direction: column-reverse;
     text-align: center;
     padding-bottom: 0px;
     margin-bottom: 30px;
   }

   #faq-main svg {
     width: 100%;
   }
 }

 #faq-row .container-fluid {
   padding: 47px 20px;
 }

 #faq-row .faq .faq-bottom {
   background: #18365e;
   padding: 10px;
   margin-top: 10px;
   margin-bottom: -24px;
   margin-left: -24px;
   margin-right: -24px;
   text-align: right;

 }

 #faq-row h5,
 #faq-row p {
   line-height: 30px;
 }

 #faq-row .faq .faq-bottom a {
   color: white;
 }

 #faq-row .faq>.row {
   transition: .4s;
   border-radius: 10px;
 }


 #faq-row .faq>.row:hover {
   background-color: #c8ebc6ff !important;
 }

 #faq-row .faq>.row:hover .faq-bottom {
   background-color: rgb(30, 102, 26) !important;
 }

 .faq-question-view .question {
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: .4s;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
 }

 .faq-question-view .question svg{
  margin-right: 10px;
 }

 .faq-question-view .answer {
   line-height: 32px;
   font-style: italic;
   margin-top: 15px;

 }

 #faq-row .no-results {
   text-align: center;
   font-size: 25px;
   color: darkgrey;
   padding: 40px 0;
 }

 #faq-row .input-group .input-group-append {
   z-index: 6;
   margin-top: 8px;
   margin-left: 14px;
   position: absolute;
   font-size: 20px;
 }

 #faq-row .input-group .form-control {
   padding: 12px 20px 12px 40px;
 }

 #faq-row .input-group .form-control::placeholder {
   color: #a7a7a7;
 }


 @media (max-width: 992px) {
   #faq-row .container-fluid {
     padding: 37px 10px;
   }
 }

 .faq-clm {
   border-left: 5px solid #18365e;
   padding: 7px;
   transition: .4s;
   border-radius: 3px;
 }

 .faq-clm:hover {
   border-left: 5px solid #2f9e29;
   background-color: #cbcbcc;
 }

 .faq-clm .card-text {
   color: #18365e;
   font-size: 20px;
 }