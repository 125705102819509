/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    color: #ffffff;
    font-size: 14px;
    background: #18365e;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact h4 {
    font-size: 22px;
    margin: 0 0 30px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #777777;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #1c84e3;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #18365e;
  }
  
  #footer .footer-newsletter {
    font-size: 15px;
  }
  
  #footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    text-align: left;
    border: 1px solid #bfdcf7;
  }
  
  #footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #18365e;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
  }
  
  #footer .footer-newsletter form input[type=submit]:hover {
    background: #1c84e3;
  }
  
  #footer .credits {
    padding-top: 5px;
    font-size: 13px;
    color: #444444;
  }
  
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #ffffff;
    color: #18365e;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .social-links a:hover {
    background: #18365e;
    color: #ffffff;
    text-decoration: none;
  }
  
  #footer .newsletter {
    background: #18365e;
  }
  
  #footer .newsletter input[type=text] {
    height: 45px;
  }
  
  #footer .newsletter .send-email {
    color: #18365e;
  }
  
  #footer .newsletter .send-email-progress {
    color: #18365e;
    font-size: 18px;
    margin-top: -4px;
  }
  
  #footer .newsletter button:disabled {
    border-color:transparent ;
  }
  
  #footer .copyright-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  #footer .copyright-center .copyright{
    display: flex;
    font-weight: 500;
  }
  
  #footer .newsletter-text {
    text-align: center;
  }
  
  @media (max-width: 992px) {
    #footer .copyright-center {
      display: flex;
      flex-direction: column;
    }
    
    #footer .copyright-center .copyright{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    #footer .copyright-center .copyright .col-sm-auto{
      margin-bottom: 10px;
    }
  
    #footer .newsletter-text h2 {
      font-size: 19px;
    }
  
    
  }
  
  
  .LegalDoc {
    background: #18365e;
    color: #fff;
  }
  
  .LegalDoc .modal-title {
    width: 100%;
  }
  
  .LegalDoc .modal-title h2 {
    text-align: center;
    margin: 0px;
    font-size: 21px;
  }
  
  .LegalDoc .btn-close {
    background-color: white;
  }
  
  .LegalDocBody p {
    text-align: justify;
  }
  
  .LegalDocBody .table-responsive {
    margin-bottom: 15px;
  }
  
  .LegalDocBody .table-responsive table {
    min-width: 1024px;
    --bs-table-bg: #dfdfdf;
    border-color: #18365e;
  }
  