
.tab-container {
  display: flex;
  background: #18365e;
  color: #fff;
  padding: 25px;
  border-radius: 10px;
}

.tab-container .tab-heads {
  cursor: pointer;
  margin-right: 15px;
  background: #ffffff;
  border-radius: 10px 0 0 10px;
  margin-left: -88px;
  border: 2px solid #18365e;
  border-right: 0px;
}

.tab-container .tab-head {
  color: #18365e;
  padding: 5px 5px;
  border-radius: 22px;
  height: 45px;
  width: 45px;
  text-align: center;
  margin-bottom: 10px;
  border: 2px solid #4CAF50;
  margin: 8px;
  transition: .4s;
}

.tab-container .tab-head img {
  width: 31px;

}

.tab-container .tab-head:hover {
  background: #dfdfdf;
}

.tab-container .tab-head.active {
  background-color: #18365e;
  color: #fff;
  border-radius: 22px 0 0 22px;
  margin-right: 0px;
  width: 53px;
  border: 2px solid #18365e;
}

.tab-container .tab-content {
  opacity: 0;
  transition: opacity 0.4s ease;
  height: 0;
  overflow: hidden;
}

.tab-container .tab-content p {
  text-align: left!important;
}

.tab-container .tab-content.active {
  opacity: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* top head */
.tab-container.top-head {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items:center;
}

.tab-container.top-head .tab-heads {
  /* border-radius: 10px 10px 0 0px; */
  margin-right:0px;
  border-radius: 10px;
  margin-left: 0px;
  margin-top: -55px;
  border: 2px solid #18365e;
}

.tab-container.top-head .tab-head {
  float: left;
}

.tab-container .tab-head.active {
  background-color: #18365e;
  color: #fff;
  /* border-radius: 22px 22px 0 0px; */
  border-radius: 22px;
  margin-bottom: 0px;
  /* height: 53px; */
  height: 45px;
  width: 45px;
  margin:8px;
  border: 2px solid #18365e;
}

.tab-container.top-head .tab-content h4 {
  margin-top: 30px;
}

@media (max-width: 992px) {
  .tab-container .tab-heads {
    margin-left: -46px;
  }
}