/* 
.navigationPage {
  background: #0000001c;
    padding: 23px 8px;
    border-radius: 7px;
} */

.mainHead {
  margin-top: -14px;
  margin-left: 10px;
}

.navigationButtons {
  margin: 20px 0px;
  text-align: center;
}

.navigationButtons button {
  background-color: #18365e;
  color: #fff;
  padding: 6px 20px;
  border-radius: 15px;
  border: none;
  font-size: 14px;
  transition: .4s;
  margin: 0px 5px;
}

.navigationButtons button:hover {
  background-color: #4CAF50;
}

.navigationButtons button:disabled {
  background-color: #747e8b;
}

.navigationButtons button .spinner {
  margin-right: 4px;
  margin-bottom: 0px;
  width: 13px;
  height: 13px;
}

@media (max-width: 767px) {

}