 /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
 #hero {
   height: 90vh;
   background-size: cover;
   margin-bottom: -200px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #c8ebc6ff;
   padding: 0px 20px;
 }

 #hero svg {
   width: 645px;
 }

 #hero .container {
   position: relative;
 }

 #hero h1 {
   margin: 0;
   font-size: 26px;
   font-weight: 700;
   line-height: 56px;
   text-transform: uppercase;
   color: #2c4964;
 }

 #hero h2 {
   color: #2c4964;
   margin: 10px 0 0 0;
   font-size: 24px;
 }

 #hero .btn-get-started {
   font-family: "Raleway", sans-serif;
   text-transform: uppercase;
   font-weight: 500;
   font-size: 14px;
   letter-spacing: 1px;
   display: inline-block;
   padding: 12px 35px;
   margin-top: 30px;
   border-radius: 50px;
   transition: 0.5s;
   color: #fff;
   background: #18365e;
   margin-right: 10px;
   border: 2px solid #18365e;
 }

 #hero .btn-get-started.secondary {
  background-color: transparent;
  color: #18365e;
 }

 #hero .btn-get-started:hover {
   background: #3f7a75;
   border: 2px solid #3f7a75;
 }

 #hero .btn-get-started.secondary:hover {
  color: #fff;
 }

 #video-hero.bg-video {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 20px;
  overflow: hidden;
  background-size: 100%;
  background-repeat: no-repeat;
 }

 #video-hero.bg-video .video {
  width: 100%;
  margin-top: -118px;
  border-bottom: 2px solid #2c4964;
 }

 #video-hero.bg-video.loading  {
  transition: .4s;
  /* background-color: #c8ebc6ff; */
 }
 #video-hero.bg-video .loading {
  display: none;
 }

 #video-hero.bg-video.loading .loading {
    display: unset;
    position: absolute;
    align-items: flex-end;
    align-content: space-between;
    justify-content: space-around;
    flex-direction: row;
    height: 50%;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    z-index: 100;
 }

 #video-hero.bg-video.loading  .video{
  opacity: .4;
 }


 #video-hero.bg-video .video-text {
  position: absolute;
  right: 67px;
  bottom: 73px;
  background: #18365eaf;
  color: white;
  padding: 49px;
  border-radius: 1px 10px 100px 1px;
  transition: .4s;
  z-index: 102;
 }

 #video-hero.bg-video .video-text:hover {
  background: #18365e;
 }

 #video-hero.bg-video .video-text h1{
  font-size: 29px;
  font-weight: 700;
 }

 #video-hero.bg-video .video-text h2{
  font-size: 20px;
 }

 #video-hero.bg-video .home-cta {
  margin-top: 15px;
 }

 #video-hero.bg-video .home-cta .btn {
  border: 2px solid #ffffff;
  padding: 8px 27px;
  border-radius: 22px;
  background: white;
  color: #18365e;
  transition: .4s;
  margin-right: 10px;
  margin-top: 5px;
  font-weight: 500;
 }

 #video-hero.bg-video .home-cta .btn.round-icon {
  font-size: 25px;
  padding: 0px 8px;
 }

 #video-hero.bg-video .home-cta .btn.round-icon svg {
  margin-top: -2px;
 }


 #video-hero.bg-video .home-cta .btn:hover {
  background: #18365e!important;
  color: #ffffff!important;
 }

 #video-hero.bg-video.loading .loading svg {
  background: white;
  padding: 17px 12px 13px 13px;
  border-radius: 45px;
  font-size: 69px;
  color: #18365e;
  animation: blinker 1.5s linear infinite;
 }


@keyframes blinker {
  50% {
    opacity: 0;
  }
}


#video-hero.is-mobile.bg-video {
  height: unset;
  display: unset!important;
}

#video-hero.is-mobile.bg-video {
  background-size: 126%;
 }

#video-hero.is-mobile.bg-video .video {
  margin-bottom: -8px;
}

#video-hero.is-mobile.bg-video .video-text{
  position:unset;
  text-align: center;

  background: #18365e;
  padding: 50px 10px;
  border-radius: 0;
  margin-top: 0px;
  margin-bottom: 10px;
}

#video-hero.is-mobile.bg-video .video-text h1{
  font-size: 24px;
 }

 #video-hero.is-mobile.bg-video .video-text h2{
  font-size: 14px;
 }

 #video-hero.is-mobile.bg-video .home-cta .btn {
  padding: 6px 15px;
 }

 #video-hero.is-mobile.bg-video.loading  {
  display: block!important;
  min-height: 250px;
 }

 #video-hero.is-mobile.bg-video.loading .loading {
  display: flex;
  height: unset;
  justify-content: center;
  margin-top: -54px;
 }

 #services .services-image-mobile-view {
  display: none;
 }

@media ((max-aspect-ratio: 1.66) or (min-aspect-ratio:2.72) or (max-width: 910px)) {
  #video-hero.bg-video {
    height: unset;
    display: unset!important;
  }

  #video-hero.bg-video {
    background-size: 126%;
   }

  #video-hero.bg-video .video {
    margin-bottom: -8px;
  }

  #video-hero.bg-video .video-text{
    position:unset;
    text-align: center;

    background: #18365e;
    padding: 50px 10px;
    border-radius: 0;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  #video-hero.bg-video .video-text h1{
    font-size: 24px;
   }
  
   #video-hero.bg-video .video-text h2{
    font-size: 14px;
   }

   #video-hero.bg-video .home-cta .btn {
    padding: 6px 15px;
   }

   #video-hero.bg-video.loading  {
    display: block!important;
    min-height: 250px;
   }

   #video-hero.bg-video.loading .loading {
    display: flex;
    height: unset;
    justify-content: center;
    margin-top: -54px;
   }
  
}

@media (max-width: 991px) {
  #video-hero.bg-video + .why-us > .container > .row {
    flex-direction: column-reverse;
  }

  #video-hero.bg-video + .why-us > .container > .row div:last-child {
    margin-bottom: 7px;
    border-radius: 10px;
  }

  #video-hero.bg-video .home-cta .btn:first-child {
    display: none;
  }

}

@media (min-width: 1200px) {
  .icon-boxes .icon-box span{
    display: block;
  }
}


 @media (max-width: 1300px) {
  #video-hero.bg-video  .video {
    width: 100%;
    margin-top: 50px;
   }
}



 .btn-top {
   font-family: "Raleway", sans-serif;
   text-transform: uppercase;
   font-weight: 500;
   font-size: 14px;
   letter-spacing: 1px;
   display: inline-block;
   padding: 12px 35px;
   border-radius: 50px;
   transition: 0.5s;
   color: #fff;
   background: rgb(5, 145, 80);
   margin-top: 113px;
   position: absolute;
   margin-left: -90px;
 }

 .btn-top:hover {
   color: #fff;
   background: rgb(4, 116, 63);
 }

 @media (max-width: 992px) {
   .btn-top {
     margin-top: 71px;
   }
 }

 @media (min-width: 1024px) {
   #hero {
     background-attachment: fixed;
   }
 }

 @media (max-width: 992px) {
   #hero {
     margin-bottom: 0;
     height: 100vh;
   }

   #hero .container {
     padding-bottom: 63px;
   }

   #hero h1 {
     font-size: 28px;
     line-height: 36px;
   }

   #hero h2 {
     font-size: 18px;
     line-height: 24px;
     margin-bottom: 30px;
   }

   #hero {
     flex-direction: column-reverse;
     text-align: center;
     padding-bottom: 115px;
     margin-bottom: 30px;
   }

   #hero svg {
     width: 100%;
   }
 }


 @media (max-height: 700px) {
   #hero h1 {
     font-size: 23px;
     line-height: 36px;
   }

   #hero svg {
     width: 85%;
   }

 }

 @media (max-width: 600px) {

  #services .services-image-mobile-view {
   display: unset;
  }

  #services .services-image-view {
   display: none;
  }

}

 @media (max-height: 600px) {
   #hero h1 {
     font-size: 23px;
     line-height: 36px;
   }

   #hero svg {
     width: 65%;
   }

   #hero .btn-get-started {
    margin-right: 0px;
   }

 }



 /*--------------------------------------------------------------
  # Why Us
  --------------------------------------------------------------*/
 .why-us .content {
   padding: 30px;
   background: #18365e;
   /* border-radius: 4px; */
   color: #fff;
   z-index: 1;
   border-radius: 1px 10px 100px 1px;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   /* text-align: justify; */
 }

 .why-us .content h3 {
   font-weight: 700;
   font-size: 34px;
   margin-bottom: 30px;
 }

 .why-us .content p {
   margin-bottom: 30px;
 }

 .why-us .content .more-btn {
   display: inline-block;
   background: rgba(255, 255, 255, 0.2);
   padding: 6px 30px 8px 30px;
   color: #fff;
   border-radius: 50px;
   transition: all ease-in-out 0.4s;
 }

 .why-us .content .more-btn i {
   font-size: 14px;
 }

 .why-us .content .more-btn:hover {
   color: #18365e;
   background: #fff;
 }

 .why-us .icon-boxes .icon-box {
   text-align: center;
   border-radius: 10px;
   background: #fff;
   box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
   /* padding: 40px 30px; */
   width: 100%;
   border: 2px solid #2c4964;
   transition: .4s;
 }

 .why-us .icon-boxes .icon-box :hover {
  background: #18365e;
  color: #fff;
}


 .why-us .icon-boxes .icon-box i {
   font-size: 40px;
   color: #18365e;
   margin-bottom: 30px;
 }

 .why-us .icon-boxes .icon-box h4 {
   /* font-size: 20px;
   font-weight: 700;
   margin: 0 0 30px 0; */

   line-height: 37px;
   font-weight: 900;
   color: #2c4964;
   font-size: 16px;
   margin-bottom: 0px;
   padding: 70px 16px;

   /* line-height: 37px;
   font-weight: 900;
   color: #2c4964;
   font-size: 18px;
   margin-bottom: 0px;
   padding: 54px 10px; */
 }

 .why-us .icon-boxes .icon-box.full-width {
    margin-top: 25px !important;
 }

 .why-us .icon-boxes .icon-box.full-width h4 {
    padding: 10px;
 }

 .why-us .icon-boxes .icon-box p {
   font-size: 15px;
   color: #848484;
 }

 /*--------------------------------------------------------------
  # Vision
  --------------------------------------------------------------*/
 .vision {
   color: #444444;
   padding: 30px 8px;
 }

 .vision .boxes h3 {
   font-size: 28px;
   font-weight: 700;
   color: #2c4964;
   margin-bottom: 15px;
 }

 .vision .boxes p {
   margin-bottom: 15px;
   font-size: 18px;
   line-height: 40px;
   text-align: left;
 }

 .vision .vision-image {
   width: 62%;
 }

 @media (max-width: 1200px) {
  .why-us .icon-boxes .icon-box h4 {
    padding: 10px;
  }
 }

 @media (max-width: 992px) {

   .vision {
     padding: 30px 8px 0px 8px;
   }

   .vision .boxes h3 {
     text-align: center;
   }

   .vision .boxes h5 {
     text-align: center;
   }

   .vision .boxes p {
     padding: 10px;
     text-align: center;
   }

   .why-us .content h3 {
     font-size: 28px;
   }
 }

 /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
 .about .icon-boxes h4 {
   font-size: 18px;
   color: #4b7dab;
   margin-bottom: 15px;
 }

 .about .icon-boxes h3 {
   font-size: 28px;
   font-weight: 700;
   color: #2c4964;
   margin-bottom: 15px;
 }


 @keyframes pulsate-btn {
   0% {
     transform: scale(0.6, 0.6);
     opacity: 1;
   }

   100% {
     transform: scale(1, 1);
     opacity: 0;
   }
 }


 /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
 .services {
    text-align: center;
    /* padding: 80px 11px; */
    transition: all ease-in-out 0.3s;
    line-height: 40px;
    background-color: #c8ebc6;

    background-repeat: no-repeat;
    background-size: cover ;
    background-attachment: fixed;
    background-position: center;
    background-color: #fff;
 }

 @supports (-webkit-touch-callout: none) {
  .services { 
    background-attachment: unset;
  } 
 } 

 .services h3 {
   font-size: 28px;
   font-weight: 700;
   color: #2c4964;
   margin-bottom: 15px;
 }

 .services p {
   line-height: 40px;
   font-size: 18px;
   text-align: justify;
 }

 .section-title {
  text-shadow: 0px 0px 2px #00000063;
 }

 .section-title h3{
  color: #000;
 }

 @media (max-width: 991px) {
   .services p {
     text-align: center;
   }

   .why-us .content {
    border-radius: 4px;
    box-shadow: unset;
    }
 }
 