 /*--------------------------------------------------------------
  # recognition-main Section
  --------------------------------------------------------------*/
 
  #recognition-main {
    height: 400px;
    background-size: cover;
    margin-bottom: -123px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c8ebc6ff;
    padding: 0px 20px;
  }

  #recognition-main svg{
    width: 645px;
  }
  
  #recognition-main .container {
    position: relative;
  }
  
  #recognition-main h1 {
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    text-transform: uppercase;
    color: #2c4964;
  }
  
  #recognition-main h2 {
    color: #2c4964;
    margin: 10px 0 0 0;
    font-size: 24px;
    line-height: 37px;
  }
  
  #recognition-main .btn-get-started {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    margin-top: 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: rgb(24, 54, 94);
  }
  
  #recognition-main .btn-get-started:hover {
    background: #3f7a75;
  }

  #recognition-row .container-fluid{
    /* border: 2px solid red; */
    padding-bottom: 5rem;
    width: 85%!important;
  }

  
  /*--------------------------------------------------------------n
  # recognition-row
  --------------------------------------------------------------*/
  .recognition-row .row img{
    margin-top: 2.5rem;
    width: 100%;
    border-radius: 10px;
    /* border: 2px solid red; */
  }

  .recognition-clm {
    border-left: 5px solid #18365e;
    background-color: #edefed;
    padding: 7px;
    transition: .4s;
    border-radius: 10px;
  }

  .recognition-clm:hover {
    border-left: 5px solid #2f9e29;
    background-color: #c8ebc6ff;
  }

  .recognition-clm .card-text {
    color: #18365e;
    font-size: 17px;
  }

  .recognition-position-achieved{
    text-align: left;
    font-size: larger;
    font-weight: bold;
    margin-top: 0.5rem ;
    margin-bottom: 0.15rem;
    /* border: 2px solid red; */
  }

  .recognition-position-achieved-date{
    /* font-family: Helvetica; */
    color: black;
    font-size: large;
    font-weight:normal;
    /* border: 2px solid red; */
  }

  .recognition-text{
    font-size: larger;
    color: #828282;

  }
   
  .slick-dots li button:before {
    font-size: 7.5px !important; /* Increase the size of the dots */
    color: green !important;    /* Change the color of the dots */
  }

  .slick-prev:before, .slick-next:before{
    /* border: 2px solid red; */
    display:none;          
    justify-content: center; 
    align-items: center;
    position: absolute;  
    z-index: 10;
    cursor: "pointer";
    width: 50px;           
    height: 50px;
    background: transparent!important;
  }

  .slick-prev{
    left: -90px!important;
  }

  .slick-next{
    right: -75px!important;
  }

   /*--------------------------------------------------------------
  # recognition-stamps
  --------------------------------------------------------------*/


  .recognition-stamps{
    width: auto;
    display: grid;
    column-gap: 25px;
    row-gap: 25px;
    grid-template-columns: auto auto auto;
    justify-content: space-around;
    margin-top: 5rem;
  
  }

  .recognition-stamps img{
    /* border: 2px solid red; */
    object-fit:fill;
    box-shadow: 2px 2px 2px 2px lightgrey;
    border-radius: 7px;
    width: 12.5rem;
    /* height: 5rem; */
    filter: grayscale(100%);
    transition: 0.5s ease;
  }

  .recognition-stamps img:hover{
    filter: grayscale(0%);
  }

.arrow-icon {
  color: green;
  font-size: 2.5rem;
}
  
  
  @media (min-width: 1024px) {
    #recognition-main {
      background-attachment: fixed;
    }
    
  .slick-prev{
    left: -40px!important;

  }

  .slick-next{
    right: -25px!important;
  }

  }
  
  @media (max-width: 992px) {
    #recognition-main {
      margin-bottom: 0;
      height: auto;
      padding-top: 104px;
    }
  
    #recognition-main .container {
      padding-bottom: 33px;
    }
  
    #recognition-main h1 {
      font-size: 28px;
      line-height: 36px;
      
    }
  
    #recognition-main h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    #recognition-main {
      flex-direction: column-reverse;
      text-align: center;
      padding-bottom: 0px;
      margin-bottom: 30px;
    }

    #recognition-main svg{
      width: 100%;
    }
  }

  @media (max-width: 991px){
    .recognition-stamps{
      grid-template-columns: 1fr 1fr;
    }

    .recognition-stamps img{
      border: transparent;
      filter: grayscale(0%);
    }
    .slick-next{
      right: -25px!important;
    }
    .slick-prev{
      left: -40px!important;
    }
  }
  
  
  @media (max-width: 768px) {

    .recognition-stamps{
      grid-template-columns: auto auto ;
    }
    

    .recognition-stamps img{
      border: transparent;
      width: 6.25rem;
      /* height: 3.75rem; */
      filter: grayscale(0%);
      
    }

  
    .slick-prev{
      left: -40px!important;
    }
  
    .slick-next{
      right: -25px!important;
    }

    .arrow-icon {
      font-size: 1.75rem;
    }
  }

  @media (max-width: 541px){
    .recognition-stamps{
      grid-template-columns: 1fr;
      justify-items: center;
    }

    .recognition-stamps img{
      width: 100%;
    }
  }