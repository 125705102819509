
.CTA-container {
  display: flex;
  justify-content: center;
}

.CTA-container .buttons-block.horizontal a:first-child{
  margin-left: 0px;
}

.CTA-container.remove-left-margin a{
  margin-left: 0px;
}

.CTA-container .buttons-block .btn{
    --bs-btn-color: #18365e;
    --bs-btn-border-color: #18365e;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #18365e;
    --bs-btn-hover-border-color: #18365e;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #18365e;
    --bs-btn-active-border-color: #18365e;
    --bs-btn-active-shadow: inset 0 3px 5px #00000020;
    --bs-btn-disabled-color: #18365e;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #18365e;
    --bs-gradient: none;
    --bs-btn-border-width: 2px;
    
}

.CTA-container .buttons-block.horizontal .btn,
.CTA-container .buttons-block.vertical .btn {
  border-radius: 20px!important;
}

.CTA-container .buttons-block.horizontal:not(:first-child), 
.CTA-container .buttons-block.horizontal .btn:not(:first-child) {
  margin-left: 4px;
}

.CTA-container .buttons-block.vertical:not(:first-child), 
.CTA-container .buttons-block.vertical .btn:not(:first-child) {
  margin-top: 4px;
  border-radius: 20px;
}


.modal-top {
  background: #18365e;
  color: #fff;
}

.modal-top div {
  text-align: center;
  margin: 0px;
  font-size: 17px;
  width: 100%;
}

.modal-top button {
  background-color: white;
}

.btn {
  margin-left: 17px;
  background: #fff;
  color: #18365e;
  border-radius: 50px;
  padding: 4px 27px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 15px;
  display: inline-block;
  border: 2px solid #18365e;
  font-weight: 600;
  padding: 7px 19px;
}

.btn:hover {
  background: #3e5c83;
  color: #ffffff;
}

.CTA-container .buttons-block.vertical .btn {
  margin-left: 0px;
}



@media (max-width: 767px) {
  .CTA-container .buttons-block{
    display: unset;
  }
}