 /*--------------------------------------------------------------
  # post-main Section
  --------------------------------------------------------------*/
 #post-main {
   height: 400px;
   background-size: cover;
   margin-bottom: -123px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #c8ebc6ff;
   padding: 0px 20px;
 }

 #post-main svg {
   width: 645px;
 }

 #post-main .container {
   position: relative;
 }

 #post-main h1 {
   margin: 0;
   font-size: 40px;
   font-weight: 700;
   line-height: 56px;
   text-transform: uppercase;
   color: #2c4964;
 }

 #post-main h2 {
   color: #2c4964;
   margin: 10px 0 0 0;
   font-size: 24px;
   line-height: 37px;
 }

 #post-main.loading{
  height: calc(100vh - 219px);
 }

 @media (min-width: 1024px) {
   #post-main {
     background-attachment: fixed;
   }
 }

 @media (max-width: 992px) {
   #post-main {
     margin-bottom: 0;
     height: auto;
     padding-top: 104px;
   }

   #post-main .container {
     padding-bottom: 33px;
   }

   #post-main h1 {
     font-size: 28px;
     line-height: 36px;
   }

   #post-main h2 {
     font-size: 18px;
     line-height: 24px;
     margin-bottom: 30px;
   }

   #post-main {
     flex-direction: column-reverse;
     text-align: center;
     padding-bottom: 0px;
     margin-bottom: 30px;
   }

   #post-main svg {
     width: 100%;
   }
 }

 #post-row .breadcrumbs {
  border-radius: 7px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: .4s;
  white-space: nowrap;
}

#post-row .breadcrumbs a{
  color: #2c4964;
  font-weight: 900;
}

#post-row .post-details {
  transition: .4s;
  padding: 10px 26px;
  border-radius: 10px;
  padding-top: 30px;
  margin-bottom: 15px;
  line-height: 15px;
  border-left: 5px solid #2f9e29;
  margin-left: 14px;
  width: calc(100% - 25px);
}

#post-row .post-details .share-button {
  transition: .4s;
  float: right;
  font-size: 12px;
  margin-top: -71px;
  border: none;
  background-color: #2c4964;
  border-radius: 5px;
  padding: 4px 14px;
  color: white;
}

#post-row .post-details .share-button:hover {
  background-color: #2f9e29;
}

#post-row .post-details .share-button svg {
  font-size: 15px;
  margin-top: -4px;
  margin-left: 5px;
}

#post-row .post-details:hover {
  background-color: #2f9e2910;
}


 #post-row .container-fluid {
   padding: 47px 20px;
 }

 #post-row .post-content {
   line-height: 33px;
   font-size: 17px;
   /* text-align: justify; */
 }

 #post-row .post-content h5{
  font-weight: 600;
}

#post-row .post-content h6{
  font-weight: 600;
}

 #post-row .post-content a{
    color: #376bb7;
    transition: .4s;
 }

#post-row .post-content a:hover{
  color: #192183;
  text-decoration: underline!important;
}

 #post-row .post-title {
  padding: 24px 12px;
  text-align: center;
  /* font-weight: 900; */
  font-size: 33px;
 }



 @media (max-width: 992px) {
   #post-row .container-fluid {
     padding: 37px 10px;
   }
 }